import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const AboutPageTemplate = ({ title, content, preamble, image, contentComponent }) => {
  const PageContent = contentComponent || Content
  const mainImage = getImage(image)

  return (
    <section className="section section--gradient content-page">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <div className="preamble">
                <p>{preamble}</p>
              </div>
              {image ? (
                <div className="columns">
                  <div className="column is-10 -is-offset-1">
                    <GatsbyImage image={mainImage} alt={title} />
                  </div>
                </div>
              ) : null}
              <PageContent className="content" content={content} />
              <div className="columns">
                <div className="column is-12 content">
                  <h3>Hitta till oss</h3>
                  <iframe title="Alviks Kontorsmaterial karta" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.0339101165675!2d17.978406515608345!3d59.332387681659874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f775e7116a3dd%3A0x454e13ce92ae9051!2sAlviks%20Kontorsmaterial%20%7C%20PostNord%2C%20DB%20Schenker%20%26%20DHL!5e0!3m2!1ssv!2sse!4v1628429977131!5m2!1ssv!2sse" width="600" height="450" style={{ 'border': '0', width: '100%' }} loading="lazy"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout pageTitle={post.frontmatter.title} pageKeywords={post.frontmatter.seokeywords} pageDescription={post.frontmatter.preamble}>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        preamble={post.frontmatter.preamble}
        image={post.frontmatter.image}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`query AboutPage {
  markdownRemark(frontmatter: {templateKey: {eq: "about-page"}}) {
    html
    frontmatter {
      seokeywords
      title
      preamble
      image {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
}
`
